import { createDevNotice, getQueryParam } from 'utils'
import { HIDDEN, SHOWN } from 'constants/classNames'
import {
  confirmCode,
  requestCode,
  selectWithPrefixedDataAttr,
  showError,
  showReloadError,
  formatPhone,
  validateCode,
  validatePhone,
  CONFIRM_PHONE_ERRORS,
  REQUEST_CODE_ERRORS,
} from 'blocksMixins/auth'
import PublishService from './api/PublishService'

const elements = {
  phoneForm: selectWithPrefixedDataAttr('phone-form'),
  phoneFormBtn: selectWithPrefixedDataAttr('phone-form-btn'),
  codeForm: selectWithPrefixedDataAttr('code-form'),
  codeFormBtn: selectWithPrefixedDataAttr('code-form-btn'),
  phoneInput: selectWithPrefixedDataAttr('phone-input'),
  codeInput: selectWithPrefixedDataAttr('code-input'),
}

let phone

/**
 * Обрабатывает отправку формы для ввода пользовательского телефона.
 * @param {Event} event
 * @returns {Promise<void>}
 */
const handleCodeFormSubmit = async event => {
  event.preventDefault()
  elements.phoneFormBtn.disabled = true

  const formattedPhone = formatPhone(elements.phoneInput.value)

  if (!validatePhone(formattedPhone)) {
    showError(REQUEST_CODE_ERRORS.invalid.message)
    elements.phoneFormBtn.disabled = false
    return
  }

  await requestCode({
    phone: formattedPhone,
    resolvedCb: correctPhone => {
      phone = correctPhone
      elements.phoneForm.classList.add(HIDDEN)
      elements.codeForm.classList.add(SHOWN)
    },
    handleErrorMessage: showError,
    showReloadError,
  })

  // eslint-disable-next-line require-atomic-updates
  elements.phoneFormBtn.disabled = false
}

/**
 * Обрабатывает отправку формы для ввода кода подтверждения телефона.
 * @param {Event} event
 * @returns {Promise<void>}
 */
const handleConfirmFormSubmit = async event => {
  event.preventDefault()
  elements.codeFormBtn.disabled = true

  const code = elements.codeInput.value

  if (!validateCode(code)) {
    showError(CONFIRM_PHONE_ERRORS.invalid.message)

    if (elements.btn) {
      elements.btn.disabled = false
    }

    return
  }

  if (!validatePhone(phone)) {
    showError(REQUEST_CODE_ERRORS.invalid.message)

    if (elements.btn) {
      elements.btn.disabled = false
    }

    return
  }

  await confirmCode({
    phone,
    code,
    resolvedCb: async () => {
      try {
        const result = await PublishService.publish(getQueryParam('bind'))
        const { url } = result.data

        window.location.assign(`${window.location.origin}${url}`)
      } catch (error) {
        createDevNotice({
          mode: 'error',
          module: 'consult/auth',
          method: 'handleConfirmFormSubmit -> confirmCode -> callback',
          description: error,
        })
        showReloadError()
      }
    },
    handleErrorMessage: showError,
    showReloadError,
  })

  // eslint-disable-next-line require-atomic-updates
  elements.codeFormBtn.disabled = false
}

if (elements.phoneForm) {
  elements.phoneForm.addEventListener('submit', handleCodeFormSubmit)
  elements.codeForm.addEventListener('submit', handleConfirmFormSubmit)
}
