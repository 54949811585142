'use strict';
$(function() {
    var draft_question_url = $('#question_form').attr('data-draft-url');
    if (draft_question_url) {
        var question_form = $('#question_form');
        question_form.change(function () { // TODO: ещё и нажатия клавиш обрабатывать
            sendDraft();
        });

        var timer = null;

        question_form.on('input', function () {
            if (timer === null) {
                timer = setTimeout(sendDraft, 5000);
            }
        });
    }

    function sendDraft() {
        $.post(draft_question_url, question_form.serialize(), function (data) {
            $('#draft_time').text(data.time).parent().show();
            timer = null;
        });
    }
});
