/**
 * @deprecated
 * Скрипт перенесён сюда
 * из шаблона consult/templates/consult.html
 * в связи с удалением assets.
 */

import { LOGIN_LINKS } from 'constants/links'

/* eslint-disable */
/**
 * consult/templates/consult.html
 */
require('imports-loader?additionalCode=>window.jQuery=jQuery;!libs/jquery.ocupload-1.1.2.packed.js');

var interval = -1;

if (!window.DATA_FROM_ASSETS.isUserAuthenticated) {
    $(".anonym_question_button").click(function (e) {
        $(".anonym_question_button").hide();
        e.preventDefault();
    });
}

if (!window.DATA_FROM_ASSETS.isUserAuthenticated || window.DATA_FROM_ASSETS.isUserPatient) {
    function check_length() {
        if ($("#id_text").length) {
            var min_cnt = 100;
            var cnt = $("#id_text").val().trim().length;

            if (cnt < 6001) {
                $('.limit_exceeded').hide();
            } else {
                $('.limit_exceeded').show();
            }
            var percent = Math.round(cnt / 100 * 100);
            $(".chars_left").text(percent)
            if (cnt >= min_cnt) {
                $(".error_left").hide();
                $("#id_text").removeClass("error_border");
            }
        }
    }

    $("#id_text").keyup(function (event) {
        check_length();
    });
}

var scroll;

function jscrollInit() {
    scroll = $('.scroll').jscroll({
        loadingHtml: '<span>Загрузка...</span>',
        nextSelector: 'a.jscroll_next:last',
        refresh: true
    });
}
jscrollInit();

var $age = $('#id_age')

$age.on('input', function() {
    var $this = $(this);
    var val = $this.val();

    if (val.length > 2) {
        $this.val(val.slice(0, 2));
    }
});

$age.blur(function() {
    var age = $(this).val()
    if(age > 99)
        $(this).val('99')
    if(age < 0)
        $(this).val('0')
    if(age == '')
        $(this).val('')
});

$("#uploaded_images").on('click','.delete_image',function(e){
    e.preventDefault();
    var id = $(this).data('image');
    var key = window.DATA_FROM_ASSETS.formKey;
    $.ajax({
        type: "POST",
        url: window.DATA_FROM_ASSETS.urls.deleteImage,
        data: {
                image: id,
                csrfmiddlewaretoken: window.csrftoken,
                key: key
            },
        success: function(data){
                    if (data == 'OK') {
                        $('#image' + id).remove();
                        $("#images_loaded").text($(".image_block").length);
                        if ($("#images_loaded").text() < 5) {
                            $('#add_image').removeAttr('disabled');
                            $('input[name=ipath]').removeAttr('disabled');
                        }
                    }
               }
    });
});

$(".question_by_number").on("click", "button", function (event) {
    var ln = ($('.question_by_number input[type=text]').val()).length;
    if (ln == 0) {
        alert("Не задан номер вопроса");
        event.preventDefault();
    }
});

$("body").on('click', '.question_link', function(e){
    window.location = $(this).data("url");
});

if ($("#images_loaded").text() >= 5) {
    $('#upload1').attr('disabled', true);
}


var upload = $('#upload1').upload({
     name: 'ipath',
     method: 'POST',
     enctype: 'multipart/form-data',
     action: window.DATA_FROM_ASSETS.urls.newImage,
     params:{csrfmiddlewaretoken: window.csrftoken, key: window.DATA_FROM_ASSETS.formKey},
     onSubmit: function() {
                $("#uploaded_images").prepend('<div id="image_placeholder" class="image_block"></div>');
                $('#add_image').attr('disabled', true);
     },
     onComplete: function(data) {
        $('#image_placeholder').remove();
        var dd=eval('('+data+')');
        if (dd.msg == "OK"){
            $("#uploaded_images").prepend('<div id="image' +  dd.id  + '" style="background-image:url(' + dd.preview_url + ');" class="image_block"><img class="delete_image" data-image="' + dd.id + '" src="/static/_v1/mo/icons/red-cross.png"></div>');
            $("#images_loaded").text($(".image_block").length);
        } else {
            alert('Произошла ошибка');
        }
        $('.upload_status').text('');
        if ($("#images_loaded").text() >= 5) {
            $('#add_image').attr('disabled', true);
            $('input[name=ipath]').attr('disabled', true);
        } else {
            $('#add_image').removeAttr('disabled');
            $('input[name=ipath]').removeAttr('disabled');
        }
        $('input[type="file"]').val(null);
     }
});

/* eslint-enable */

$('#add_image').click(() => {
  $('input[name="ipath"]').click()
  return false
})

if (window.location.hash === '#ask' && !window.USER.isAnonymous) {
  $('.show_question_form').hide()
  $('.new_question').show()
}

function openForm() {
  if (window.USER.isAnonymous) {
    window.location.assign(`${LOGIN_LINKS.medtochka}${window.location.hash !== '#ask' ? '%23ask' : ''}`)
  } else {
    $('.show_question_form').hide('slow')
    $('.new_question').slideDown('slow')
  }

  return false
}

$('.show_question_form').click(openForm)
