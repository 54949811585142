import { axiosClient } from 'utils'
import Cookie from 'js-cookie'

export default {
  publish(questionKey) {
    return axiosClient.post(
      window.CONSULT_PUBLISH_URL,
      `key=${questionKey}`,
      {
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      },
    )
  },
}
